import axios from 'axios';

const WP_API_URL = process.env.REACT_APP_WP_API_URL;

const api = axios.create({
  baseURL: WP_API_URL,
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json'
  }
});

// Add an axios interceptor to include the token with each request
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem('jwt_token');
    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }
    return config;
  },
  error => {
    console.error('Request interceptor error:', error);
    return Promise.reject(error);
  }
);

// Add an axios interceptor to handle token refresh and error logging
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    console.group('API Error Details');
    console.error('Error:', error.message);
    if (error.response) {
      console.error('Status:', error.response.status);
      console.error('Data:', error.response.data);
      console.error('Headers:', error.response.headers);
    } else if (error.request) {
      console.error('Request was made but no response was received');
      console.error('Request:', error.request);
    }
    console.error('Config:', error.config);
    console.groupEnd();

    const originalRequest = error.config;
    if (error.response && error.response.status === 401) {
      // Token is expired or invalid, redirect to login
      localStorage.removeItem('jwt_token');
      window.location.href = '/login';
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export const login = async (username, password) => {
  try {
    const response = await api.post('/jwt-auth/v1/token', { username, password });
    if (response.data && response.data.token) {
      localStorage.setItem('jwt_token', response.data.token);
    }
    return response.data;
  } catch (error) {
    console.error('Login error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    }
    throw error;
  }
};

export const getBrands = async () => {
  try {
    const response = await api.get('/dezygn/v1/brands');
    return response.data;
  } catch (error) {
    console.error('Get brands error:', error);
    throw error;
  }
};

export const getSavedDesigns = async () => {
  try {
    const response = await api.get('/dezygn/v1/saved-designs');
    return response.data;
  } catch (error) {
    console.error('Get saved designs error:', error);
    throw error;
  }
};

export const getEditedImages = async () => {
  try {
    const response = await api.get('/dezygn/v1/edited-images');
    return response.data;
  } catch (error) {
    console.error('Get edited images error:', error);
    throw error;
  }
};

export const createEditedImage = async (imageData) => {
  try {
    const response = await api.post('/dezygn/v1/edited-images', imageData);
    return response.data;
  } catch (error) {
    console.error('Create edited image error:', error);
    throw error;
  }
};

export const createBrand = async (brandData) => {
  try {
    console.log('Creating brand with data:', brandData);
    const response = await api.post('/dezygn/v1/brands', brandData);
    console.log('Create brand response:', response);
    return response.data;
  } catch (error) {
    console.error('Create brand error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const updateBrand = async (brandId, brandData) => {
  try {
    console.log(`Updating brand ${brandId} with data:`, brandData);
    console.log('API URL:', `${WP_API_URL}/dezygn/v1/brands/${brandId}`);
    const response = await api.put(`/dezygn/v1/brands/${brandId}`, brandData);
    console.log('Update brand response:', response.data);
    return response.data;
  } catch (error) {
    console.error('Update brand error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    } else if (error.request) {
      console.error('Error request:', error.request);
    } else {
      console.error('Error message:', error.message);
    }
    throw error;
  }
};

export const updateUserSettings = async (userData) => {
  try {
    const response = await api.put('/wp/v2/users/me', userData);
    return response.data;
  } catch (error) {
    console.error('Update user settings error:', error);
    throw error;
  }
};

export const getCurrentUser = async () => {
  try {
    const response = await api.get('/wp/v2/users/me');
    return response.data;
  } catch (error) {
    console.error('Get current user error:', error);
    throw error;
  }
};

export const deleteBrand = async (brandId) => {
  try {
    const response = await api.delete(`/dezygn/v1/brands/${brandId}`);
    return response.data;
  } catch (error) {
    console.error('Delete brand error:', error);
    throw error;
  }
};

export const getGenerations = async () => {
  try {
    const response = await api.get('/dezygn/v1/generations');
    return response.data;
  } catch (error) {
    console.error('Get generations error:', error);
    throw error;
  }
};

export const createGeneration = async (generationData) => {
  try {
    // Validate generationData
    if (!generationData || typeof generationData !== 'object') {
      throw new Error('Invalid generation data provided');
    }

    // Ensure fields object exists
    if (!generationData.fields) {
      generationData.fields = {};
    }

    // Validate tokens_used
    if (typeof generationData.fields.tokens_used === 'undefined') {
      console.warn('tokens_used is not provided in generationData');
      generationData.fields.tokens_used = generationData.fields.num_images || 1; // Default to number of images or 1
    }

    console.log('Creating generation with data:', generationData);
    const response = await api.post('/dezygn/v1/generations', generationData);
    console.log('Generation created successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Create generation error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    }
    throw error;
  }
};

export const createSavedDesign = async (designData) => {
  try {
    // Validate designData
    if (!designData || typeof designData !== 'object') {
      throw new Error('Invalid design data provided');
    }

    // Ensure fields object exists
    if (!designData.fields) {
      designData.fields = {};
    }

    // Validate tokens_used
    if (typeof designData.fields.tokens_used === 'undefined') {
      console.warn('tokens_used is not provided in designData');
      designData.fields.tokens_used = 1; // Default to 1 token for saving
    }

    console.log('Creating saved design with data:', designData);
    const response = await api.post('/dezygn/v1/saved-designs', designData);
    console.log('Design saved successfully:', response.data);
    return response.data;
  } catch (error) {
    console.error('Create saved design error:', error);
    if (error.response) {
      console.error('Error response:', error.response.data);
      console.error('Error status:', error.response.status);
      console.error('Error headers:', error.response.headers);
    }
    throw error;
  }
};

export const getUserStats = async () => {
  try {
    const response = await api.get('/dezygn/v1/user-stats');
    return response.data;
  } catch (error) {
    console.error('Get user stats error:', error);
    throw error;
  }
};

export const getUserPlanDetails = async () => {
  try {
    const response = await api.get('/dezygn/v1/user-plan');
    return response.data;
  } catch (error) {
    console.error('Get user plan details error:', error);
    throw error;
  }
};

export const getUserUsageStats = async () => {
  try {
    const response = await api.get('/dezygn/v1/user-usage-stats');
    // Ensure the response includes token usage information
    if (!response.data.tokens_used) {
      console.warn('Token usage information is missing in the response');
    }
    return response.data;
  } catch (error) {
    console.error('Get user usage stats error:', error);
    throw error;
  }
};
